.titulo{
    float: right;
    font-size: 15px;
  }
  .color{
    --background: #e5eee5;
    color: aqua;
  }
  .header{
    --background: rgb(21, 10, 170);
    --color: white;
  }
  .body{
    --background: url('../login.png');
    
  }

