.titulo{
    float: right;
    font-size: 15px;
  }

  .header{
    --background: #003057;
    --color: rgb(253, 253, 253);
  }
  .body{
    --background: #ffffff;
     --background: url('../fodohome.png') no-repeat;
    
  }
  .center{
    --text-align: center;
  }
  .subheader{
    --background: #787a1381;
    --color: rgb(253, 253, 253);
  }

  ion-modal#example-modal {
    --height: 50%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  ion-modal#example-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }
  
  ion-modal#example-modal ion-toolbar {
    --background: #003057;
    --color: white;
  }
  ion-col {
    font-size: 11px;
  }
  ion-title {
    font-size: 13px;
  }
  ion-avatar {
    width: 200px;
    height: 200px;
    --text-align: center;
    }
    ion-loading.custom-loading {
      --background: #f2f3f6;
      --spinner-color: #003057;
    
      color: #030544;
    }

    ion-radio {
      --border-radius: 4px;
      --inner-border-radius: 4px;
    
      --color: #ddd;
      --color-checked: #6815ec;
    }
    
    ion-radio.ios {
      width: 20px;
      height: 20px;
    
      border: 2px solid #ddd;
      border-radius: 4px;
    }
    
    .radio-checked.ios {
      border-color: #6815ec;
    }
    .fondohome{
      --background: rgb(0,48,87);
     --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
     --color: rgb(230, 238, 232);
   }