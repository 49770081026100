ion-radio {
    width: 30px;
    height: 30px;
  }
  
  ion-radio::part(container) {
    border-radius: 8px;
    border: 2px solid #ddd;
  }
  
  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }
  
  ion-radio.radio-checked::part(container) {
    background: #6815ec;
    border-color: transparent;
  }
  
  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
  
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: hsl(0, 0%, 100%);
  
    transform: rotate(45deg);
  }
  .item-background-color{
    --ion-item-background: url('../nivel44.jpg') no-repeat;
    background-color: transparent !important; 
}
.item-background-color2{
  --ion-item-background: url('../nivel33.jpg') no-repeat;
  background-color: transparent !important; 
}
.item-background-color3{
  --ion-item-background: url('../nivel22.jpg') no-repeat;
  background-color: transparent !important; 
}
.item-background-color4{
  --ion-item-background: url('../nivel.jpg') no-repeat;
  background-color: transparent !important; 
}
.item-background-color5{
  --ion-item-background: #ddd6e4;
  background-color: transparent !important; 
}
.fondohome{
  --background: rgb(0,48,87);
 --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
 --color: rgb(230, 238, 232);
}
.placa{
  /* --ion-item-background: url('../placa_fondo.png') no-repeat;
  background-color: transparent !important; 
  width: 250px;
  height: 200px; */
  text-align: center;
  padding: 10px; /* Ajusta el valor del padding según tus necesidades */
  border: 5px solid #f7ea40; /* Borde con color gris claro */
  border-radius: 5px; /* Bordes redondeados */
  border-spacing: 5px;
  --ion-item-background:#f7ea40;
  font-size: 40px;
  font-weight: bold;

}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll; /* Habilita el desplazamiento horizontal 1189026  1190538*/ 
  white-space: nowrap; /* Evita que el contenido se ajuste automáticamente a la siguiente línea */
  height: 100%;
  padding: 0 10px; /* Añade un espacio alrededor del contenido para el desplazamiento */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease;
}

.zoomed {
  transform: scale(3); /* Cambiar el valor de escala según el nivel de zoom deseado */
  cursor: zoom-out;
}