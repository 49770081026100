.titulo{
    float: right;
    font-size: 15px;
  }
  .boton{
    --background: #A8AD00;
    color: #fafafa;
  }
  .header{
    --background: rgb(92, 160, 216);
    --color: rgb(230, 238, 232);
  }
  .body{
    --background: rgb(0,48,87);
    --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
    
  }
  .center{
    --text-align: center;
  }
  .logo1{
    --width: 200px;
  }
  .label{
    --background-color: #A8AD00;
    color: #006e18;
  }
  .fondohome{
    --background: rgb(0,48,87);
   --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
   --color: rgb(230, 238, 232);
 }
 .FondoItem{
  --background: rgb(176, 253, 196);
 
 --color: rgb(1, 10, 3);
}
.FondoItemNo{
  --background: rgb(248, 211, 211);
 
 --color: rgb(1, 10, 3);
}

.FondoImp{
  --background: rgb(235, 175, 175);
}