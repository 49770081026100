.titulo{
    float: right;
    font-size: 15px;
  }
  .color{
    --background: #A8AD00;
    color: #fafafa;
     border-radius: 20px;
  }
  .header{
    --background: #A8AD00;
    --color: white;
  }
  .fondo{
    --background: rgb(0,48,87);
    --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
    
  }
  .center{
    --text-align: center;
  }
  .logo1{
    --width: 50px;
  }
  .logop{
    --width: 70% !important;
  }

  .centered-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-image-container {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: left;
  }
  
  .centered-image {
    width: 200px;
    height: 200px;
    border-radius: 100px;
  }
 
  .botonv2{
    background: #A8AD00;
    color: #fafafa;
    border-radius: 20px;
    width: 260px;
    height: 50px;
    size: 12px;
    padding: 15px 15px 15px 35px;
  }
  .inputv2{
    background: #fdfcfc;
    border-radius: 20px;
    border-color: #fafafa;
    border: 1px solid #fefefe;
    width: 300px;
    height: 60px;
  }
  .checkv2{
    background: #c5c5c5;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    padding-left: 20;
    
  }


  .input-wrapper {
    position: relative;
    width: 271px; 
 }
  
 .input {
    box-sizing: border-box;
    color: #191919;
    padding: 15px 15px 15px 35px;
    width: 260px;
    border-radius: 20px;
    text-transform: lowercase;
 }
 .input2 {
  box-sizing: border-box;
  color: #191919;
  padding: 15px 15px 15px 35px;
  width: 260px;
  border-radius: 20px;
}
 .input.password {
    padding: 15px 35px 15px 15px;
 }
  
 .input-icon {
    color: #191919;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
 }
  
 .input-icon.password {
    left: unset;
    right: 12px;
 }