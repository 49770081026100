.titulo{
    float: right;
    font-size: 15px;
  }
  .letra{
    font-size: 20px;
  }
  .boton{
    --background: #A8AD00;
    color: #fafafa;
  }
  .header{
   /*  --background: url('../fodohome.png') no-repeat; */
    --background: #003057;
    --color: rgb(138, 153, 175);
   
  }
  .body{
    --background: #003057;
    
  }
  .fondohome{
     --background: rgb(0,48,87);
    --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
    --color: rgb(230, 238, 232);
  }
  .capa{
    width: 100%;
    height: 100vh;
    --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgba(162,196,223,1) 100%);
    opacity: 0.7;
  }
  .center{
    --text-align: center;
  }
  .logo1{
    --width: 200px;
  }
  ion-menu::part(backdrop) {
    background-color: rgba(49, 92, 86, 0.5);
  }
  
  ion-menu::part(container) {
    border-radius: 0 20px 20px 0;
  
    box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
  }
  .mi-color-personalizado {
    color: #A8AD00; /* Reemplaza #ff8800 con el color personalizado que desees */
  }
 