.titulo{
    float: right;
    font-size: 15px;
  }
  .boton{
    --background: #A8AD00;
    color: #fafafa;
  }
  .header{
    --background: #003057;
    --color: rgb(230, 238, 232);
  }
  .body{
    --background: #f0f0f0;
     --background: url('../fodohome.png') no-repeat;
    
  }
  .center{
    --text-align: center;
  }
  .logo1{
    --width: 200px;
  }
  .label{
    --background-color: #A8AD00;
    color: #006e18;
  }

  .fondohome{
    --background: rgb(0,48,87);
   --background: linear-gradient(180deg, rgba(0,48,87,1) 0%, rgb(92, 160, 216) 100%);
   --color: rgb(230, 238, 232);
 }